<template>
  <van-cell-group inset style="margin-top: 40px">
    <van-cell
      title="关于"
      size="large"
      label="本平台由山东眯去来网络科技有限公司提供相关服务,
    在使用过程遇到任何问题请联系我们,我们的客服热线是:400-6622205 服务时间: 8:30 - 17:30"
    />
  </van-cell-group>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { Cell, CellGroup } from "vant";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    const router = useRouter(); //传值

    const toAbout = () => {
      router.replace("/");
    };
    const onLogout = () => {
      localStorage.clear();
      router.replace("/");
    };

    const toResetPwd = () => {
      router.push({
        path: "/resetpwd",
      });
    };

    onMounted(() => {
      /* eslint-disable */
      upsdk.pluginReady(function () {
        upsdk.setNavigationBarTitle({
          title: "关于",
        });
        upsdk.setTitleStyle({
          backBtnVisible: "1",
          navBackgroundColor: "0xff1989fa",
        });
      });
      payType.value = Route.query.payType;
      onLoad();
    });
    return {
      onLogout,
      toResetPwd,
      toAbout,
    };
  },
};
</script>


<style lang="less">
</style>>